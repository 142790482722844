import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, Form, Input, Typography, Checkbox, notification } from 'antd';
import { UserCredentialsContext } from './App';
import { handleUserRequest } from './utils';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { checkAndSaveDomainStatus } from './utils';
import { LoadingOutlined } from '@ant-design/icons';

import './LoginPage.css';

function LoginPage() {
  const [emailLogin, setEmailLogin] = useState('');
  const [passwordLogin, setPasswordLogin] = useState('');
  const [emailRegister, setEmailRegister] = useState('');
  const [passwordRegister, setPasswordRegister] = useState('');
  const [showLogin, setShowLogin] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const setUserCredentials = useContext(UserCredentialsContext);
  const [agreedToLicense, setAgreedToLicense] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [seatextId, setSeatextId] = useState('');

  function cleanEmail(email) {
    // First, trim any leading or trailing whitespace
    email = email.trim();
    
    // Then, remove any characters that are definitely not allowed in emails
    return email.replace(/[^\w!#$%&'*+/=?^`{|}~@.-]/g, '');
  }

  useEffect(() => {
    let parameterReceived = false;
  
    // Check for seatextId in URL
    const params = new URLSearchParams(location.search);
    const seatextIdFromUrl = params.get('seatextId');
    if (seatextIdFromUrl) {
      setSeatextId(seatextIdFromUrl);
      parameterReceived = true;
    }
  
    const hash = location.hash.slice(1); // Remove the '#' at the start
    if (hash) {
      try {
        const decodedHash = atob(hash);
        const params = new URLSearchParams(decodedHash);
        const login = params.get('login');
        const password = params.get('password');
        if (login && password) {
          handleAutoLogin(login, password);
          parameterReceived = true;
        }
      } catch (error) {
        console.error('Error decoding hash:', error);
      }
    } else if (!Cookies.get('email') || !Cookies.get('password')) {
      setShowLogin(false);
    }
  
    // Set the wordpress cookie if any parameter was received
    if (parameterReceived) {
      Cookies.set('wordpress', '1', { expires: 7 }); // Cookie expires in 7 days
    }
  }, [location]);

  async function handleAutoLogin(email, password) {
    setIsLoading(true);
    try {
      const cleanedEmail = cleanEmail(email);
      const success = await handleUserRequest(true, cleanedEmail, password, setUserCredentials, navigate, handleError, seatextId);
      if (success) {
        await checkAndSaveDomainStatus();
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleError = (error) => {
    setIsLoading(false); // Stop loading on error
  };

  useEffect(() => {
    // Check if email and password cookies exist, and show the registration screen if not
    if (!Cookies.get('email') || !Cookies.get('password')) {
      setShowLogin(false);
    }
  }, []);

  async function handleLoginSubmit() {
    setIsLoading(true);
    const cleanedEmail = cleanEmail(emailLogin);
    const success = await handleUserRequest(true, cleanedEmail, passwordLogin, setUserCredentials, navigate, handleError, seatextId);
    if (success) {
      await checkAndSaveDomainStatus();
    }
    setIsLoading(false);
  }
  
  async function handleRegisterSubmit() {
    setIsLoading(true);
    try {
      const cleanedEmail = cleanEmail(emailRegister);
      const registerResult = await handleUserRequest(false, cleanedEmail, passwordRegister, setUserCredentials, navigate, handleError, seatextId);
      
      if (registerResult.success) {
        setIsLoading(false);
      } else {
        // If registration fails, attempt to log in
        const loginResult = await handleUserRequest(true, cleanedEmail, passwordRegister, setUserCredentials, navigate, handleError, seatextId);
        
        if (loginResult.success) {
          notification.success({
            message: 'Login Successful',
            description: 'You already had an account. You have been logged in.',
            duration: 3,
          });
        } else {
          notification.error({
            message: 'Account Creation Failed',
            description: 'An account with this email already exists. Please try logging in.',
            duration: 3,
          });
          setShowLogin(true); // Switch to login form
        }
      }
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="login-page-container">
      <div className="external-content-container">
        <iframe src="https://seatext.com/registration" frameBorder="0" title="SEATEXT Registration" />
      </div>
  
      <div className="auth-container" data-seatextdont>
        <div className={`auth-section ${showLogin ? '' : 'hidden'}`}>
          <Typography.Title level={2} style={{ textAlign: 'center' }}>Sign In</Typography.Title>
          <Form onFinish={handleLoginSubmit}>
            <Form.Item
              name="emailLogin"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input
                id="email-login"
                placeholder="Email Address"
                onChange={(e) => setEmailLogin(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="passwordLogin"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password
                id="password-login"
                placeholder="Password"
                onChange={(e) => setPasswordLogin(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Button htmlType="submit" block style={{
              backgroundColor: '#000',
              borderColor: '#000',
              color: '#fff',
              fontWeight: 'bold',
              height: '50px',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }} disabled={isLoading}>
              {isLoading ? (
                <>
                  <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />
                  <span style={{ marginLeft: 8 }}>Signing In...</span>
                </>
              ) : (
                "Sign In"
              )}
            </Button>
          </Form>
          <Button type="link" onClick={() => setShowLogin(false)}>
            Don't have an account? 
          </Button>
        </div>
  
        <div className={`auth-section ${showLogin ? 'hidden' : ''}`} data-seatextdont>
          <Typography.Title level={2} style={{ textAlign: 'center' }}>Create account</Typography.Title>
          <div className="benefits-section register-benefits">
            <ul>
              <li>Takes 10 seconds, no need for email confirmation</li>
            </ul>
          </div>
          <Form onFinish={handleRegisterSubmit}>
            <Form.Item
              name="emailRegister"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input
                id="email-register"
                placeholder="Email Address"
                onChange={(e) => setEmailRegister(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Form.Item
              name="passwordRegister"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password
                id="password-register"
                placeholder="Password"
                onChange={(e) => setPasswordRegister(e.target.value)}
                size="large"
              />
            </Form.Item>
            <Button type="primary" htmlType="submit" block style={{
              backgroundColor: '#000',
              borderColor: '#000',
              color: '#fff',
              fontWeight: 'bold',
              height: '50px',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }} disabled={isLoading || !agreedToLicense}>
              {isLoading ? (
                <>
                  <LoadingOutlined style={{ fontSize: 20, color: '#fff' }} spin />
                  <span style={{ marginLeft: 8 }}>Registering...</span>
                </>
              ) : (
                "Continue"
              )}
            </Button>
            <Form.Item name="agreement" valuePropName="checked" initialValue={true}>
              <Checkbox onChange={(e) => setAgreedToLicense(e.target.checked)} checked={agreedToLicense}>
                I agree to the{" "}
                <Link to="/license-agreement" data-seatextdont> AI Fair Usage Policy</Link>
              </Checkbox>
            </Form.Item>
          </Form>
  
          <Button type="link" onClick={() => setShowLogin(true)}>
            Already have an account? Sign In
          </Button>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;