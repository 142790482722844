import React, { useState, useEffect, useRef } from 'react';
import { Layout, Typography, Menu } from 'antd';
import { Link, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import Lottie from 'react-lottie';
import Cookies from 'js-cookie';
import {
  ApartmentOutlined, EditOutlined, ShopOutlined, AimOutlined, TranslationOutlined,
  UserOutlined, MobileOutlined, BulbOutlined, UploadOutlined, DownloadOutlined,
  AppstoreOutlined, BookOutlined, GlobalOutlined, ForkOutlined, LogoutOutlined
} from '@ant-design/icons';

import SeatextdemoWordPress from './SeatextdemoWordPress';

import "./welcome.css";
import { checkAndSaveDomainStatus, fetchLanguageCodesAndVariants } from './utils';
import Seatextdemo from './seatextdemo';
import AISalesPersonalisation from './AIsalespersonalisation';
import AIMarketingPersonalisation from './AIMarketingPersonalisation';
import TranslationSettingsPage from './Settingstranslation';
import VariantsEditPage from './VariantEdit';
import PlanSelectAndPayment from './PlanSelectAndPayment';
import YoutubeVideoPage from './YoutubeVideoPage';
import FAQPage from './FAQPage';
import ImageUploader from './upload';
import VariantNext from './variantnext';
import SettingsPage2 from './SettingsPage2';
import Etrade from './etrade';
import Mobile from './mobile';
import LoginPage from './LoginPage';

const { Content, Sider } = Layout;
const { Text } = Typography;

const defaultOptions = {
  loop: true,
  autoplay: true, 
  animationData: null,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice'
  },
  path: 'https://lottie.host/529bf41e-e8d4-44dc-ad85-ac20b44fcd6a/jtT5t8jdkx.json'
};

export default function WelcomePage() {
  const [selectedKey, setSelectedKey] = useState('Seatextdemo');
  const [domainName, setDomainName] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [menuWidth, setMenuWidth] = useState(300);
  const siderRef = useRef(null);
  const [menuItems, setMenuItems] = useState([]);
  const labelRef = useRef(null);
  const [isWordPress, setIsWordPress] = useState(false);
  const [statsData, setStatsData] = useState({
    languages: 0,
    variants: 0
  });
  const navigate = useNavigate();



  const updateStats = async (domain) => {
    try {
      const data = await fetchLanguageCodesAndVariants(domain);
      setStatsData({
        languages: data.languages.length || 0,
        variants: data.variants || 0,
        domainLabel: domain
      });
    } catch (error) {
      console.error('Failed to update stats:', error);
    }
  };

  useEffect(() => {


    
    // Existing logic for domain status and data fetching
    checkAndSaveDomainStatus();
  
    const domainFromCookie = Cookies.get('domainName');
    const domainName = domainFromCookie || "seatext.com"; 
    setDomainName(domainName); 
  
// Check for WordPress cookie
const wordPressCookie = Cookies.get('wordpress');
setIsWordPress(wordPressCookie === '1');


    // Fetch data using the appropriate URL
    const fetchUrl = domainFromCookie ? `${domainFromCookie} Whole website` : "seatext.com";
    fetchLanguageCodesAndVariants(fetchUrl) 
      .then(data => {
        setStatsData({
          languages: data.languages.length || 0,
          variants: data.variants || 0,
          domainLabel: fetchUrl
        });
      })
      .catch(error => {
        console.error('Failed to load language codes and variants:', error);
      });
  
    // Navigate if domain exists
    if (domainFromCookie) { 
      navigate("/welcome/aimanager"); 
    }
  
    setIsLoading(false);
  
    // Set up interval for checking domain status
    const intervalId = setInterval(checkDomainStatus, 1000);

     // Set menu items
     setMenuItems(getMenuItems());

    // Measure the width of the longest label
    const measureLabelWidth = () => {
      if (labelRef.current) {
        const labels = labelRef.current.querySelectorAll('.ant-menu-title-content');
        let maxWidth = 300; // minimum width
        labels.forEach(label => {
          const labelWidth = label.offsetWidth;
          if (labelWidth > maxWidth) {
            maxWidth = labelWidth;
          }
        });
        setMenuWidth(Math.min(maxWidth + 80, 400)); // add padding, max 400px
      }
    };



    // New event listener for iframe message
    const handleIframeMessage = async (event) => {
      if (event.data.type === 'FORM_SUBMISSION_SUCCESS') {
        console.log('Form submitted successfully:', event.data.data);
        const isDomainConnected = await checkAndSaveDomainStatus();
        if (isDomainConnected) {
          const newDomain = Cookies.get('domainName') || localStorage.getItem('domainName');
          setDomainName(newDomain);
          setStatsData({
            languages: 0,
            variants: 0,
            domainLabel: newDomain
          });
          await updateStats(`${newDomain} Whole website`);
          navigate("/welcome/aimanager");
        }
      }
    };
    
      window.addEventListener('message', handleIframeMessage);
  
  
  
        
        




    measureLabelWidth();
    window.addEventListener('resize', measureLabelWidth);




    // Set menu items
    setMenuItems(getMenuItems());

    // Cleanup function
    return () => {
      clearInterval(intervalId);
      window.removeEventListener('message', handleIframeMessage);
    };
  }, []);






  const checkDomainAndRedirect = async () => {
    const isDomainConnected = await checkAndSaveDomainStatus();
    if (isDomainConnected) {
      const newDomain = Cookies.get('domainName') || localStorage.getItem('domainName');
      setDomainName(newDomain);
      navigate("/welcome/aimanager");
    }
  };

  const checkDomainStatus = async () => {
    const domainStatus = await checkAndSaveDomainStatus();
    const newDomain = Cookies.get('domainName');
    setDomainName(newDomain);
    return domainStatus;
  };






  const getMenuItems = () => [
    
    {
      key: 'aimanager',
      icon: <ApartmentOutlined />,
      label: 'Main AI Hub',
      description: 'Assign specific AI models to different pages',
      link: '/welcome/aimanager'
    },
    {
      key: 'variantEdit',
      icon: <EditOutlined />,
      label: 'Review AI Performance & Adjust AI Text Variants',
      description: 'Edit AI variants in any language and see current status of testing',
      link: '/welcome/variant-edit'
    },
    {
      key: 'aiProduct',
      icon: <ShopOutlined />,
      label: 'AI for Online Stores',
      description: 'AI model refines product descriptions for maximum conversions',
      link: '/welcome/ai-product'
    },
    {
      key: 'aiImprovesConversion',
      icon: <AimOutlined />,
      label: 'General AI model improves any website sales',
      description: 'General AI model enhances conversion rates over time on any website',
      link: '/welcome/SettingsPage2'
    },
    {
      key: 'ai-translation',
      icon: <TranslationOutlined />,
      label: 'AI Website Translation',
      description: 'Automatically translates your website into 125 languages',
      link: '/welcome/TranslationSettingsPage'
    },
    {
      key: 'AIsales',
      icon: <UserOutlined />,
      label: 'AI personalize prospect experience',
      description: 'AI personalize prospect experience on your website',
      link: '/welcome/AIsales'
    },
    {
      key: 'aiMobile',
      icon: <MobileOutlined />,
      label: 'AI Mobile Optimization',
      description: 'AI ensures your mobile pages are concise and impactful',
      link: '/welcome/ai-mobile'
    },
    {
      key: 'AIMarketingPersonalisation',
      icon: <BulbOutlined />,
      label: 'AI personalize based on traffic source',
      description: 'AI personalizes landing pages based on traffic source and visitor data',
      link: '/welcome/AIMarketingPersonalisation'
    },
    {
      key: 'imageUploader',
      icon: <UploadOutlined />,
      label: 'Video and Image Uploader',
      description: 'Upload pictures to the translated version of the website',
      link: '/welcome/image-upload'
    },
    {
      key: 'seatext-install',
      icon: <DownloadOutlined />,
      label: '! How to Install SEATEXT  on your website',
      description: '! Step-by-step guide to install SEATEXT on your website',
      link: '/welcome/seatextdemo'
    },
    {
      key: 'plans-usage',
      icon: <AppstoreOutlined />,
      label: 'Fair Usage Policy/Paid usage',
      description: 'Monitor AI usage to stay within the fair usage policy or switch to Paid plan',
      link: '/welcome/plan-selection'
    },
    {
      key: 'documentation',
      icon: <BookOutlined />,
      label: 'Documentation',
      description: 'Access comprehensive documentation and user guides',
      link: 'https://seatext.com/documentation'
    },
    {
      key: 'feedback',
      icon: '😠',
      label: ' Not happy with SEATEXT AI?',
      description: 'Let us know your concerns',
      link: 'https://forms.clickup.com/9011325169/p/f/8chvu7h-451/0O1I9MLY3I2R6AI1P2/form',
      className: 'feedback-item'
    }
  ];
  if (isLoading) {
    return <div>Loading...</div>;
  }


  return (
    <div data-seatextdont>
      <Layout style={{ minHeight: '100vh' }} data-seatextdont>
        <Sider 
          ref={siderRef}
          className="custom-sider"
          width={menuWidth}
          style={{ maxWidth: '400px' }}
          data-seatextdont
        >
          <div className="logo-container" data-seatextdont>
            <div className="lottie-logo" data-seatextdont>
              <Lottie options={defaultOptions} />
            </div>
          </div>
          <div style={{ height: '20px' }} data-seatextdont></div>
          <div className="client-info" data-seatextdont>
            <div className="domain-name" data-seatextdont>
              <GlobalOutlined />
              <span data-seatextdont>
                {domainName 
                  ? domainName
                  : isWordPress 
                    ? 'Follow instructions to activate AI on your website.'
                    : 'DEMO account. Install on your website to start.'
                }
              </span>
            </div>
            <div className="stats-container" data-seatextdont>
  <div className="stat-item" data-seatextdont>
    <TranslationOutlined />
    <Text data-seatextdont>
      Website translated to languages: {statsData.languages}
    </Text>
  </div>

              <div className="stat-item" data-seatextdont>
                <ForkOutlined />
                <Text data-seatextdont>{statsData.variants} Variants in MVT Testing</Text>
              </div>
            </div>
          </div>
          <Menu
            ref={labelRef}
            theme="dark"
            mode="inline"
            selectedKeys={[selectedKey]}
            className="custom-menu"
            data-seatextdont
            style={{ width: '100%' }}
          >
            {menuItems.map(item => (
              item.key === 'spacer' ? (
                <div key={item.key} className={item.className} data-seatextdont />
              ) : (
                <Menu.Item key={item.key} icon={item.icon} className={item.className} data-seatextdont>
                  <Link to={item.link} data-seatextdont>
                    <span className="menu-item-label" data-seatextdont>{item.label}</span>
                    {item.description && (
                      <span className="menu-item-description" data-seatextdont>{item.description}</span>
                    )}
                  </Link>
                </Menu.Item>
              )
            ))}
          </Menu>
        </Sider>
        <Layout className="site-layout" data-seatextdont>
          <Content className="site-content" data-seatextdont>
            <div className="content-container" data-seatextdont>
              <Routes>
            <Route path="/" element={
  isWordPress 
    ? <Navigate replace to="SeatextdemoWordPress" />
    : <Navigate replace to="Seatextdemo" />
} />
              <Route path="plan-selection" element={<PlanSelectAndPayment />} />
              <Route path="variant-edit" element={<VariantsEditPage/>} />
              <Route path="learn" element={<YoutubeVideoPage />} />
              <Route path="faq" element={<FAQPage />} />
              <Route path="/TranslationSettingsPage" element={<TranslationSettingsPage  />} />
              <Route path="/SettingsPage2" element={<SettingsPage2  />} />
              <Route path="aimanager" element={<VariantNext />} />
              <Route path="AIsales" element={<AISalesPersonalisation />} />
              <Route path="ai-mobile" element={<Mobile />} />
              <Route path="ai-product" element={<Etrade />} />
              <Route path="image-upload" element={<ImageUploader />} />
              <Route path="Seatextdemo" element={<Seatextdemo />} />
              <Route path="AIMarketingPersonalisation" element={<AIMarketingPersonalisation />} />
              <Route path="SeatextdemoWordPress" element={<SeatextdemoWordPress />} />
              <Route path="login" element={<LoginPage />} />
            </Routes>
          </div>
        </Content>
      </Layout>
    </Layout>
    </div>
  );
}