import React, { useState, useEffect, useCallback } from 'react';
import {
  Select, List, Typography, Input, Button, Divider, Form, Progress, Switch, Flex, Space, Tooltip, Card, Spin, Checkbox
} from 'antd';
import { MenuUnfoldOutlined, SearchOutlined, SaveOutlined, EditOutlined, DeleteOutlined, LeftOutlined, RightOutlined, CheckCircleOutlined, CloseCircleOutlined, DeleteFilled } from '@ant-design/icons';
import { getPageList, getVariantsForUrl, updateVariantOnServer, getLanguagesForUrl, sendDeleteVariantRequest, sendFeatureStatusToServer3, generateVariantsWithAI, deleteAllVariantsForPage } from './utils';
import LottieAnimation from './LottieAnimation';
import { updateVariantApprovalStatus } from './utils';

const { TextArea } = Input;
const { Option } = Select;
const { Title, Text } = Typography;
const PAGE_SIZE = 30;


const EditableTextArea = React.memo(({ initialValue, onSave }) => { 
  const [tempValue, setTempValue] = useState(initialValue);
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFocus = () => setIsEditing(true);
  const handleBlur = () => {
    setIsEditing(false);
    onSave(tempValue);
  };

  return (
    <TextArea
      value={tempValue}
      onChange={(e) => setTempValue(e.target.value)}
      onFocus={handleFocus}
      onBlur={handleBlur}
      autoSize={{ minRows: 1, maxRows: 8 }}
      style={{ marginBottom: '8px', width: '100%' }}
    />
  );
});

const VariantMetrics = React.memo(({ variant, onApprovalChange }) => {

  const conversionRate = variant.Conversions && variant.Views
   ? (parseFloat(variant.Conversions) / parseFloat(variant.Views)) * 100
     : 0;
  // Calculate confidence score
  const confidence = Math.min((parseFloat(variant.Views) / 2000) * 100, 100);

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', width: '30%', alignItems: 'center', marginRight: '10px' }}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%' }}>
        <Checkbox
          checked={variant.VariantAllowed} 
          onChange={(e) => onApprovalChange(variant.ID, e.target.checked)}
          style={{ marginBottom: '5px' }}
        />
        <Text type="secondary" style={{ fontSize: '9px' }}>
          Approved
        </Text>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%' }}>
        <Tooltip title="Conversion Score: This percentage represents the probability that this text variant is performing well based on MVT (Multivariate Testing)">
          <Progress
            percent={Number(conversionRate)}
            format={(percent) => `${percent.toFixed(0)}%`}
            strokeColor="#1890ff"
          />
        </Tooltip>
        <Text type="secondary" style={{ fontSize: '9px' }}>
          Conv. Score
        </Text>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '30%' }}>
        <Tooltip title="Confidence: This AI-estimated score represents how sure the model is about the conversion rate for this variant">
          <Progress
            percent={Number(confidence)}
            format={(percent) => `${percent.toFixed(0)}%`}
            strokeColor="#1890ff"
          />
        </Tooltip>
        <Text type="secondary" style={{ fontSize: '9px' }}>
          Confidence
        </Text>
      </div>
    </div>
  );
});


const cleanUrl = (url) => url ? url.replace(/ Whole website$/, '').trim() : '';




export default function VariantEdit({ initialUrl, initialLanguage }) {
  console.log("Received initialUrl:", initialUrl);

  const cleanedInitialUrl = initialUrl ? initialUrl.replace(/ Whole website$/, '').trim() : '';
  
  console.log("Cleaned initialUrl:", cleanedInitialUrl);
  const [approvedStatus, setApprovedStatus] = useState({});
  const [variants, setVariants] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(30);


  const [selectedLanguage, setSelectedLanguage] = useState(initialLanguage || '');
  const [urls, setUrls] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [pageIndex, setPageIndex] = useState(0);
  const [aiCreationStates, setAiCreationStates] = useState({});
  const [deletingVariantId, setDeletingVariantId] = useState(null);
  const [loading, setLoading] = useState(false);

  const cleanUrl = (url) => url.replace(/ Whole website$/, '').trim();

  const [selectedUrl, setSelectedUrl] = useState(cleanUrl(initialUrl || ''));



  useEffect(() => {
    console.log("selectedUrl in useEffect:", selectedUrl);
    const fetchPageList = async () => {
      if (!initialUrl) {
        const pageList = await getPageList();
        if (pageList) {
          setUrls(pageList);
        }
      }
    }
    fetchPageList();
  }, [initialUrl]);

  useEffect(() => {
    if (initialUrl) {
      setSelectedUrl(initialUrl);
      fetchLanguages(initialUrl);
    }
  }, [initialUrl]);

  useEffect(() => {
    if (initialLanguage) {
      setSelectedLanguage(initialLanguage);
    }
  }, [initialLanguage]);

  const fetchVariants = useCallback(async (page = 1) => {
    if (selectedUrl && selectedLanguage) {
      setLoading(true);
      const cleanedUrl = cleanUrl(selectedUrl);
      try {
        const { variants, totalCount, currentPage } = await getVariantsForUrl(cleanedUrl, selectedLanguage, page, pageSize);
  
        if (!variants || variants.length === 0) {
          console.error("No variants returned from getVariantsForUrl");
          setLoading(false);
          return;
        }
  
        setVariants(variants);
        setTotalCount(totalCount);
        setCurrentPage(currentPage);
      } catch (error) {
        console.error("Error fetching variants:", error);
      } finally {
        setLoading(false);
      }
    }
  }, [selectedUrl, selectedLanguage, pageSize]);

  useEffect(() => {
    console.log("selectedUrl in useEffect:", selectedUrl);
    const fetchPageList = async () => {
      if (!initialUrl) {
        const pageList = await getPageList();
        if (pageList) {
          setUrls(pageList);
        }
      }
    }
    fetchPageList();
  }, [initialUrl]);

  useEffect(() => {
    if (initialUrl) {
      setSelectedUrl(initialUrl);
      fetchLanguages(initialUrl);
    }
  }, [initialUrl]);

  useEffect(() => {
    if (initialLanguage) {
      setSelectedLanguage(initialLanguage);
    }
  }, [initialLanguage]);

  useEffect(() => {
    console.log("Fetching variants for URL:", selectedUrl, "and language:", selectedLanguage || initialLanguage);
    fetchVariants(1);
  }, [selectedUrl, selectedLanguage, initialLanguage, fetchVariants]);

  const handleNextPage = () => {
    fetchVariants(currentPage + 1);
  };
  
  const handlePreviousPage = () => {
    fetchVariants(currentPage - 1);
  };

  const fetchLanguages = async (url) => {
    if (url) {
      const languagesData = await getLanguagesForUrl(url);
      if (languagesData && Array.isArray(languagesData)) {
        setLanguages(languagesData);
      }
    }
  };


  const sortedLanguages = React.useMemo(() => {
    // Remove 'English' from the array if it exists
    const withoutEnglish = languages.filter(lang => lang !== 'English');
    
    // Sort the remaining languages by length
    const sorted = withoutEnglish.sort((a, b) => a.length - b.length);
    
    // Add 'English' back to the beginning if it was in the original array
    return languages.includes('English') ? ['English', ...sorted] : sorted;
  }, [languages]);





  useEffect(() => {
    if (selectedUrl) {
      fetchLanguages(selectedUrl);
    }
  }, [selectedUrl]);

  const handleUrlChange = (value) => {
    setSelectedUrl(cleanUrl(value));
  };

  const handleLanguageChange = (value) => {
    setSelectedLanguage(value);
  };

  const handleVariantSave = (originalIndex, variantIndex, newText) => {
    setVariants(prevVariants => {
      const newVariants = JSON.parse(JSON.stringify(prevVariants));
      newVariants[originalIndex][2][variantIndex].tempText = newText;
      newVariants[originalIndex][2][variantIndex].edited = true;
      return newVariants;
    });
  };

  const handleAddNewVariant = (originalIndex) => {
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      newVariants[originalIndex][2].push({ renderKey: `new-${Date.now()}`, ID: 999, Text: '', tempText: '' });
      return newVariants;
    });
  };
  const handleDeleteVariant = async (pageOriginalIndex, pageVariantIndex) => {
    const globalOriginalIndex = pageIndex * PAGE_SIZE + pageOriginalIndex;
    const variantToDelete = variants[globalOriginalIndex]?.[2]?.[pageVariantIndex];
  
    if (!variantToDelete) {
      console.error("Invalid variant selection for deletion");
      return;
    }
  
    // Prepare the delete request data
    const deleteRequestData = {
      Url: cleanUrl(selectedUrl),
      Language: selectedLanguage,
      OriginalText: variants[globalOriginalIndex][0],
      VariantText: variantToDelete.Text,
    };
  
    // Remove the variant from the state
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      if (newVariants[globalOriginalIndex] && newVariants[globalOriginalIndex][2]) {
        if (newVariants[globalOriginalIndex][2].length === 1) {
          // If this is the last variant, remove the entire original text entry
          newVariants.splice(globalOriginalIndex, 1);
        } else {
          // Otherwise, just remove the specific variant
          newVariants[globalOriginalIndex][2].splice(pageVariantIndex, 1);
        }
      }
      return newVariants;
    });
  
    // Send delete request to server
    try {
      const deleteResponse = await sendDeleteVariantRequest(deleteRequestData);
      if (deleteResponse.success) {
        console.log("Variant deleted successfully on the server");
        // After successful deletion, re-fetch the variants to ensure UI is in sync with server
        fetchVariants(currentPage);
      } else {
        console.error("Server error:", deleteResponse.error);
      }
    } catch (error) {
      console.error("An error occurred while deleting the variant:", error);
    }
  };
  
  const removeVariantFromState = (globalOriginalIndex, variantIndex) => {
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      if (newVariants[globalOriginalIndex] && newVariants[globalOriginalIndex][2]) {
        newVariants[globalOriginalIndex][2].splice(variantIndex, 1);
        // If this was the last variant, the entire original text entry will be removed
        if (newVariants[globalOriginalIndex][2].length === 0) {
          newVariants.splice(globalOriginalIndex, 1);
        }
      }
      return newVariants;
    });
  };

  const handleAiCreationChange = async (checked, originalIndex) => {
    const originalText = variants[originalIndex][0];
    const cleanedUrl = cleanUrl(selectedUrl);
    const language = selectedLanguage;
  
    setVariants(prevVariants => {
      const newVariants = [...prevVariants];
      newVariants[originalIndex][1] = checked;
      return newVariants;
    });
  
    setAiCreationStates(prevStates => ({
      ...prevStates,
      [originalText]: checked
    }));
  
    try {
      await sendFeatureStatusToServer3({
        cleanedUrl,
        language,
        originalText,
        status: checked
      });
      console.log('AI creation state sent to server');
    } catch (error) {
      console.error('Error sending AI creation state to server:', error);
    }
  };




  
// Update the handleSaveEdits function to include VariantAllowed
const handleSaveEdits = async () => {
  const cleanedUrl = cleanUrl(selectedUrl);
  const dataToSend = variants
    .map((variantData, originalIndex) => {
      const [originalText, aiSwitch, variants, language] = variantData;
      const processedVariants = variants
        .filter(variant => variant.tempText !== undefined || variant.VariantAllowed !== undefined)
        .map(variant => ({
          ID: variant.ID,
          Text: variant.tempText ? variant.tempText : variant.Text,
          VariantAllowed: variant.VariantAllowed !== undefined ? variant.VariantAllowed : true
        }));

      if (processedVariants.length > 0) {
        return {
          Url: cleanedUrl,
          Language: language,
          Text: originalText,
          Variants: processedVariants,
          AiCreation: aiSwitch
        };
      }
      return null;
    })
    .filter(Boolean);

  if (dataToSend.length > 0) {
    try {
      await updateVariantOnServer(dataToSend);
      console.log("Variants updated successfully!");
    } catch (error) {
      console.error("Error updating variants:", error);
    }
  } else {
    console.log("No changes to send to the server.");
  }
};

  const handleGenerateAIVariants = async (originalIndex) => {
    const originalText = variants[originalIndex][0];
    const cleanedUrl = cleanUrl(selectedUrl);
    try {
      const { success, data } = await generateVariantsWithAI(cleanedUrl, selectedLanguage, originalText);
      if (success) {
        setVariants(prevVariants => {
          const newVariants = JSON.parse(JSON.stringify(prevVariants));
          const existingVariantIds = new Set(newVariants[originalIndex][2].map(v => v.ID));
          
          const filteredNewVariants = data.filter(variant => !existingVariantIds.has(variant.ID));
          
          newVariants[originalIndex][2] = [
            ...newVariants[originalIndex][2],
            ...filteredNewVariants.map(variant => ({
              ...variant,
              renderKey: `variant-${variant.ID}`,
              displayedConversionRate: parseFloat(variant.ConversionRate) * 100,
              confidence: Math.min(variant.Views / 100 * 100, 100)
            }))
          ];
          return newVariants;
        });
        console.log("AI variants added successfully");
      } else {
        console.error("Failed to generate AI variants:", data.error);
      }
    } catch (error) {
      console.error("Error generating AI variants:", error);
    }
  };


  const filteredVariants = variants.filter(variant => variant[3] === selectedLanguage);
  const pageVariants = filteredVariants.slice(pageIndex * PAGE_SIZE, (pageIndex + 1) * PAGE_SIZE);



  const handleDeleteAll = async () => {
    if (!selectedUrl || !selectedLanguage) {
      console.error("URL or language not selected");
      return;
    }

    const confirmation = window.confirm("Are you sure you want to delete all variants for this page and language? This action cannot be undone.");
    if (!confirmation) return;

    setLoading(true);
    const result = await deleteAllVariantsForPage(selectedUrl, selectedLanguage);
    setLoading(false);

    if (result.success) {
      console.log('All variants deleted successfully');
      // Reset the variants state
      setVariants([]);
      // Reset the language selection
      setSelectedLanguage('');
      // Optionally, you might want to refetch the languages for the URL
      fetchLanguages(selectedUrl);
    } else {
      console.error('Failed to delete variants:', result.error);
      // Optionally, show an error message to the user
      alert(`Failed to delete variants: ${result.error}`);
    }
  };

  const handleApprovalChange = async (variantId, checked) => {
    try {
      await updateVariantApprovalStatus({
        url: cleanUrl(selectedUrl),
        language: selectedLanguage,
        variants: [{ id: variantId, approved: checked }]
      });
  
      setVariants(prevVariants => {
        const newVariants = [...prevVariants];
        newVariants.forEach(variantGroup => 
          variantGroup[2].forEach(v => { 
            if (v.ID === variantId) v.VariantAllowed = checked; 
          })
        );
        return newVariants;
      });
    } catch (error) {
      console.error("Failed to update variant approval status:", error);
      // Optionally, show an error message to the user
    }
  };
  
  const handleApproveAll = async () => {
    try {
      await updateVariantApprovalStatus({
        url: cleanUrl(selectedUrl),
        language: selectedLanguage,
        bulkApprove: true
      });

      setVariants(prevVariants => {
        return prevVariants.map(variantGroup => [
          variantGroup[0],
          variantGroup[1],
          variantGroup[2].map(v => ({ ...v, VariantAllowed: true })),
          variantGroup[3]
        ]);
      });
    } catch (error) {
      console.error("Failed to approve all variants:", error);
      // Optionally, show an error message to the user
    }
  };

  const handleDisapproveAll = async () => {
    try {
      await updateVariantApprovalStatus({
        url: cleanUrl(selectedUrl),
        language: selectedLanguage,
        bulkDisapprove: true
      });

      setVariants(prevVariants => {
        return prevVariants.map(variantGroup => [
          variantGroup[0],
          variantGroup[1],
          variantGroup[2].map(v => ({ ...v, VariantAllowed: false })),
          variantGroup[3]
        ]);
      });
    } catch (error) {
      console.error("Failed to disapprove all variants:", error);
      // Optionally, show an error message to the user
    }
  };

  const buttonStyle = {
    border: '1px solid #d9d9d9',
    borderRadius: '4px',
    height: '40px',
    padding: '0 20px',
    fontSize: '14px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f8f8f8', 
   
  };


  return (
    <div data-seatextdont>
      <div style={{ width: '100%', minWidth: '1086px' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={{ width: '100%' }}>
            <Card bordered={false} style={{ width: '100%' }}>
            <div className="ai-card-content" style={{ 
                marginBottom: '30px', 
                marginTop: '0px', 
                backgroundColor: '#f8f8f8', 
                minHeight: '350px', 
                padding: '20px',
                border: '1px solid #A9A9A9' // Changed to dark grey
              }}>
                <div className="lottie-container" style={{ float: 'left', marginRight: '20px', marginLeft: '20px', marginTop: '40px' }}>
                  <LottieAnimation
                    animationPath="https://lottie.host/8eb52a8d-3298-466f-9b2b-c62f0e04e193/LaIwtdYRte.json"
                    width={900}
                    height={200}
                  />
                </div>
                <div className="ai-text-content">
                  <div className="ai-title-container">
                    <h2 className="ai-title" style={{ fontSize: '22px', marginBottom: '10px', marginTop: '40px' }} data-seatextdont>
                      See results of MVT (A/B) testing and AI text variants
                    </h2>
                  </div>
                  <div className="ai-description" style={{ fontSize: '16px', color: 'rgba(0,0,0,0.65)' }}>
  seatextdont You can see how the AI automatically creates new text variants and view the current testing results. Once one of the tested variants reaches an 85% confidence score and has the highest conversion score, the AI will automatically prioritize it, allocating at least 80% of the traffic to this variant while continuing to test other variants for even better versions. The first variant is a copy of your original website text, as the AI needs to compare its performance against the AI-generated variants. You can always edit and add your own variants for testing.

  <p>
    Warning! If you see only one variant for some of your texts, it means that the AI either decided not to produce variants due to the current AI settings or the created variants did not pass internal SEATEXT AI filters. If you still want to generate AI variants for such sections, press "Generate AI Variants" — in this case, variants will be generated without any filters or settings applied for that particular text on your website.
  </p>

  <p>
    To approve variants, click on the empty box labeled "Approved" next to each variant, or delete the variants you don't like, then press "Approve All Variants." If you don't want to manually approve all variants for this page, go to the AI settings for this page and activate automatic approval.
  </p>
                  </div>
                  <div data-seatextdont data-seatexttranslateoff>
                  {/* Integrated form for URL and language selection */}
                  <Form layout="vertical" style={{ marginTop: '20px' }}>
                    {!initialUrl && (
                      <Form.Item label="Please choose page you want to edit:">
                        <Select value={selectedUrl} onChange={handleUrlChange}>
                          {urls.map((url, index) => (
                            <Option value={url} key={index}>{url}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                    
                    {selectedUrl && !initialLanguage && (
                      <Form.Item label="Please choose language:">
                        <Select value={selectedLanguage} onChange={handleLanguageChange}>
                          {sortedLanguages.map((language, index) => (
                            <Option value={language} key={index}>{language}</Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )}
                  </Form>
                  </div>


                  
                  <div style={{ marginLeft: '0px', display: 'flex', alignItems: 'center' }}>
                  <Tooltip title="Delete all variants for the selected page and language. This does not mean that AI will stop working for this page—it will continue creating new variants and testing them. If you want to turn off AI, you need to choose the URL and turn off AI in the Main AI hub. You can also disapprove all variants, and this will make AI stop doing anything except gathering information for its learning model.">

          <Button 
            onClick={handleDeleteAll} 
            style={{ ...buttonStyle, backgroundColor: '#f8f8f8', color: 'black' }}
            icon={<DeleteFilled />}
          >
            Delete All Variants
          </Button>
           </Tooltip>
            <Tooltip title="Approve all variants for the selected page and language">
          <Button 
            onClick={handleApproveAll}  // Connected to handleApproveAll function
            style={{ ...buttonStyle, marginLeft: '20px', backgroundColor: '#f8f8f8', color: 'black' }}
            icon={<CheckCircleOutlined />}
          >
               Approve All Variants
             </Button>
           </Tooltip>
           <Tooltip title="Disapprove all variants for the selected page and language. This means that AI testing for this page will be stopped immediately. AI will only use conversational data for your original website to improve its predictions.">
          <Button 
            onClick={handleDisapproveAll}  // Connected to handleDisapproveAll function
            style={{ ...buttonStyle, marginLeft: '20px', backgroundColor: '#f8f8f8', color: 'black' }}
            icon={<CloseCircleOutlined />}
          >
               Disapprove All Variants
             </Button>
           </Tooltip>
         </div>
         <Text style={{ marginLeft: '00px', fontSize: '14px', marginRight: '80px', marginTop: '10px' }}>
           If you want all Variants to be approved automatically, turn on automatic approval for this URL in settings of AI models you are using.
         </Text>
       </div>
                </div>



                
              







            </Card>


            
          </div>
        </div>
      </div>
     
      

      <Spin spinning={loading} tip="Loading variants... This may take up to 10 seconds." size="large">
        <div style={{ padding: '20px', minHeight: '400px' }} data-seatexttranslateoff>
          {!loading && variants.length > 0 && (
            <List>
              {variants.map((variantData, originalIndex) => {
  const [originalText, aiSwitch, variantsForOriginal, language] = variantData;
  return (
    <List.Item key={`original-${originalIndex}`} style={{ width: '100%' }}>
      <div style={{ backgroundColor: 'white', boxShadow: '1px 1px 1px 1px rgba(0,0,0,0.2)', padding: '20px', marginBottom: '5px', width: '100%' }}>
      <Title level={4} style={{ borderBottom: '2px solid #E8E8E8', paddingBottom: '10px', marginBottom: '20px' }} data-seatexttranslateoff data-seatextdont>
      <span style={{ fontSize: '12px', color: 'grey' }}>Website original text:</span>
      <span style={{ fontSize: '18px', color: 'black', marginLeft: '5px' }}>{originalText}</span>
    </Title>
        {variantsForOriginal.map((variant, variantIndex) => (
          <div key={`variant-${originalIndex}-${variantIndex}`} style={{ marginBottom: '16px', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <VariantMetrics
                variant={variant}
                onApprovalChange={handleApprovalChange}
              />
              <EditableTextArea
                initialValue={variant.tempText || variant.Text}
                onSave={(newText) => handleVariantSave(originalIndex, variantIndex, newText)}
              />
              <Button
                icon={<DeleteOutlined />}
                style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'normal', fontSize: '12px' }}
                onClick={() => handleDeleteVariant(originalIndex, variantIndex)}
              >
                Delete Variant
              </Button>
            </div>
          </div>
        ))}
                      <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <Button
                          icon={<SaveOutlined />}
                          style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'normal', fontSize: '14px' }}
                          onClick={handleSaveEdits}
                        >
                          Save Edits
                        </Button>
                        <Button
                          icon={<EditOutlined />}
                          style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'normal', fontSize: '14px' }}
                          onClick={() => handleAddNewVariant(originalIndex)}
                        >
                          Add New Variant
                        </Button>
                        <Button
                          icon={<EditOutlined />}
                          style={{ background: 'transparent', borderColor: 'transparent', fontWeight: 'normal', fontSize: '14px' }}
                          onClick={() => handleGenerateAIVariants(originalIndex)}
                        >
                          Generate AI Variants
                        </Button>

                        <div style={{ marginLeft: '10px' }}>
                          <Switch
                            checked={aiSwitch}
                            onChange={(checked) => handleAiCreationChange(checked, originalIndex)}
                            checkedChildren="AI ON"
                            unCheckedChildren="AI OFF"
                          />
                          <span style={{ marginLeft: '10px' }}>Allow AI to create more new variants if all variants are tested</span>
                        </div>
                      </div>
                    </div>
                  </List.Item>
                );
              })}
            </List>
          )}
          
             {/* Pagination Controls */}
             {!loading && variants.length > 0 && (
  <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
    <Button
      onClick={handlePreviousPage}
      disabled={currentPage === 1}
      icon={<LeftOutlined />}
      style={{ marginRight: '30px' }}
    >
      Previous Page
    </Button>
    <Button
      onClick={handleNextPage}
      disabled={currentPage * pageSize >= totalCount}
      icon={<RightOutlined />}
    >
      Next Page
    </Button>
    <Text style={{ marginLeft: '20px' }}>
      Page {currentPage} of {Math.ceil(totalCount / pageSize)}
    </Text>
  </div>
)}
          
        </div>
      </Spin>
    </div>
  );
}